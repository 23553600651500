import { useEffect } from 'react'
import { data, Outlet } from 'react-router'
import { getToast } from 'remix-toast'
import { toast as notify, Toaster } from 'sonner'
import { type Route } from './+types/_layout'
import { Footer } from '~/components/ui/footer'
import { Header } from '~/components/ui/header'
import { type SEOHandle } from '~/utils/sitemap'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

export async function loader({ request }: Route.LoaderArgs) {
	const { toast, headers } = await getToast(request)
	return data({ toast }, { headers })
}

export default function Layout({
	loaderData: { toast },
}: Route.ComponentProps) {
	useEffect(() => {
		if (toast?.type === 'error') {
			notify.error(toast.message)
		}
		if (toast?.type === 'success') {
			notify.success(toast.message, {
				dismissible: true,
				closeButton: true,
				duration: 60000,
			})
		}
	}, [toast])
	return (
		<div className="container mx-auto flex min-h-screen flex-col justify-between space-y-6 md:mt-4 md:space-y-12 lg:space-y-24">
			<div className="md:space-y-6 lg:space-y-12">
				<Header />
				<div className="space-y-16">
					<Outlet />
					<Toaster richColors position="top-center" />
				</div>
			</div>
			<Footer />
		</div>
	)
}
